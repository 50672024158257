import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import BackgroundAmorphusGradientDesktop from "../../images/background_amorphus_gradient_desktop_from_bottom.svg";
import {
  OpaliteTextSubTitle,
  OpaliteTextTitle,
  OpaliteTextStandard,
} from "../shared/opalite_text";
import AmorphusGradient from "../../images/background_amorphus_gradient.svg";

interface FAQProps {}

const FrequentlyAskedQuestions: React.FC<FAQProps> = (props) => {
  return (
    <Box
      paddingTop="6rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100%"
      backgroundRepeat="no-repeat"
      backgroundImage={{
        base: "unset",
        sm: BackgroundAmorphusGradientDesktop,
      }}
      backgroundSize="100%"
      backgroundPosition="0 3.2rem"
      backgroundAttachment="fixed"
    >
      <Box padding="2rem" width={{ base: "100%", sm: "600px" }}>
        <OpaliteTextTitle marginBottom="2rem">FAQ</OpaliteTextTitle>
        <OpaliteTextSubTitle marginBottom="1rem" fontSize="1.75rem">
          What is an NFT?
        </OpaliteTextSubTitle>
        <OpaliteTextStandard>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
        <OpaliteTextSubTitle marginBottom="1rem" fontSize="1.75rem">
          How do I earn from NFT sale?
        </OpaliteTextSubTitle>
        <OpaliteTextStandard>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
        <OpaliteTextSubTitle marginBottom="1rem" fontSize="1.75rem">
          Do I need to have a crypto wallet?
        </OpaliteTextSubTitle>
        <OpaliteTextStandard>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
        <OpaliteTextSubTitle marginBottom="1rem" fontSize="1.75rem">
          How much does Opalite cost?
        </OpaliteTextSubTitle>
        <OpaliteTextStandard>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
      </Box>
      <Image
        src={AmorphusGradient}
        display={{ base: "block", sm: "none" }}
        width="100%"
      />
    </Box>
  );
};

export default FrequentlyAskedQuestions;
