import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import FrequentlyAskedQuestions from "../components/FAQ/FAQ";
import Header from "../components/header/header";
import Footer from "../components/shared/footer";

function AllNftPage() {
  return (
    <ChakraProvider>
      <Header />
      <FrequentlyAskedQuestions />
      <Footer />
    </ChakraProvider>
  );
}

export default AllNftPage;
